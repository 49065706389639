<template>
  <div>
    <div>Rows per page:</div>
    <v-select
      v-model="localRowsPerPage"
      class="pa-0 ma-0 rows-per-page-select"
      dense
      :items="rowsPerPageOptions"
      hide-details
      @change="handleRowsPerPageChange"
    >
    </v-select>
    <div>{{ pageStart }}-{{ pageEnd }} of {{ totalItems }}</div>
    <div>
      <v-btn small icon :disabled="currentPage === 1" @click="goToPreviousPage">
        <v-icon small> fas fa-chevron-left</v-icon>
      </v-btn>
      <v-btn
        small
        icon
        :disabled="currentPage === totalPages"
        @click="goToNextPage"
      >
        <v-icon small> fas fa-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    rowsPerPage: {
      type: Number,
      default: 50,
    },
    rowsPerPageOptions: {
      type: Array,
      default: () => [5, 10, 25, 50, 100],
    },
  },
  data() {
    return {
      currentPage: 1,
      localRowsPerPage: this.rowsPerPage,
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.localRowsPerPage)
    },
    pageStart() {
      return this.totalItems === 0
        ? 0
        : (this.currentPage - 1) * this.localRowsPerPage + 1
    },
    pageEnd() {
      return Math.min(
        this.pageStart + this.localRowsPerPage - 1,
        this.totalItems
      )
    },
  },
  watch: {
    totalItems() {
      this.updateCurrentPage(1)
    },
  },
  methods: {
    handleRowsPerPageChange() {
      this.$emit("update:rows-per-page", this.localRowsPerPage)
      this.updateCurrentPage(1)
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.updateCurrentPage(this.currentPage - 1)
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.updateCurrentPage(this.currentPage + 1)
      }
    },
    updateCurrentPage(value) {
      this.currentPage = value
      this.$emit("update-page", this.currentPage)
    },
  },
}
</script>

<style lang="scss">
.rows-per-page-select {
  width: min-content;
  margin-left: 10px;
}
</style>
