<template>
  <div class="nvr-filters pt-4">
    <div class="grid-header">Filters</div>

    <div class="grid-surface grid-filters pa-2">
      <slot name="actions"></slot>
      <!-- GLOBAL SEARCH -->
      <EGlobalSearch
        :dark="$vuetify.theme.dark"
        :items="searchInputItems"
        :is-fixed-position="false"
        :is-hidden="false"
        class="ma-2"
        :deep="false"
        @search-results="onGlobalSearchResultsChange"
        @reset="onGLobalSearchResultsReset"
      />

      <!-- CAMERA STATUS -->
      <v-select
        v-model="selectedCameraStatus"
        outlined
        dense
        hide-details
        label="Camera Status"
        :items="cameraStatuses"
        :menu-props="{ maxHeight: 'none' }"
        class="color-select ma-2 white"
        @change="(s) => (selectedCameraStatus = s)"
      >
        <template #item="{ item }">
          <span :class="getStatusColor(item)">{{ item.text }}</span>
        </template>
        <template #selection="{ item }">
          <span :class="getStatusColor(item)">{{ item.text }}</span>
        </template>
      </v-select>

      <!-- CAMERA MODEL -->
      <v-select
        v-model="selectedCameraModel"
        outlined
        dense
        hide-details
        label="Camera Model"
        :items="cameraModels"
        :menu-props="{ maxHeight: 'none' }"
        class="color-select ma-2 white"
        @change="(s) => (selectedCameraModel = s)"
      />

      <!-- CAMERA FEATURE FLAG -->
      <v-select
        v-model="selectedFeatureFlag"
        outlined
        dense
        hide-details
        label="Camera Feature Flag"
        :items="featureFlags"
        :menu-props="{ maxHeight: 'none' }"
        class="color-select ma-2 white"
        @change="(s) => (selectedFeatureFlag = s)"
      />

      <!-- CAMERA CREATION DATE -->
      <v-menu
        v-model="isCreationDatePickerOpened"
        :close-on-content-click="false"
        max-width="290"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="creationDateRangeDisplay"
            label="Camera Creation Date"
            placeholder="Camera Creation Date"
            persistent-placeholder
            readonly
            clearable
            dense
            outlined
            hide-details
            :menu-props="{ maxHeight: 'none' }"
            class="color-select ma-2 white"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="creationDateRange"
          range
          @change="onCreationDateRangeChange"
        />
      </v-menu>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import {
  CAMERA_FEATURE_FLAGS,
  CAMERA_STATUSES_IDS_CLASSES,
  CAMERA_STATUSES_LIST,
} from "@/components/constants.js"
import {
  AdminCamera,
  CameraStatus,
  CameraModel,
  GlobalSearchResult,
  CameraFeatureFlag,
} from "@evercam/shared/types"

export default Vue.extend({
  name: "ExNvrFiltersPanel",
  props: {
    cameras: {
      type: Array as PropType<AdminCamera[]>,
      required: true,
    },
  },
  data() {
    return {
      globalSearchResults: [] as GlobalSearchResult[],
      searchResults: [] as AdminCamera[],
      isSearchActive: false,
      selectedCameraModel: CameraModel.Milesight,
      selectedCameraStatus: CameraStatus.Online,
      selectedFeatureFlag: "" as CameraFeatureFlag,
      cameraModels: Object.values(CameraModel),
      isCreationDatePickerOpened: false,
      creationDateRange: [],
    }
  },
  computed: {
    creationDateRangeDisplay: {
      get() {
        return this.creationDateRange?.join(" - ")
      },
      set() {
        this.creationDateRange = []
      },
    },
    cameraStatuses() {
      const cameraStatuses = CAMERA_STATUSES_LIST.map(
        ({ value, name: text }) => ({ text, value })
      )

      return [{ text: "All", value: "" }, ...cameraStatuses]
    },
    featureFlags() {
      const featureFlags = CAMERA_FEATURE_FLAGS.map(
        ({ value, name: text }) => ({ text, value })
      )

      return [{ text: "All", value: "" }, ...featureFlags]
    },
    searchInputItems(): Record<any, any>[] {
      return this.cameras.map((c) => [
        c.name,
        c.projectExid,
        c.exid,
        c.projectName,
        c.createdAt,
        c.cameraModel,
        c.status,
        c.userFullname,
      ])
    },
    filteredCameras(): AdminCamera[] {
      let filteredCameras = this.cameras

      if (this.isSearchActive) {
        filteredCameras = this.globalSearchResults.reduce<AdminCamera[]>(
          (acc, match) => {
            return [...acc, this.cameras[match.index]]
          },
          []
        )
      }

      return filteredCameras.filter((c) => {
        const matchesFeatureFlag =
          !this.selectedFeatureFlag ||
          c.featureFlags?.includes(this.selectedFeatureFlag)

        const matchesDateRange =
          !this.creationDateRange.length ||
          (this.$moment(c.createdAt).isSameOrAfter(
            this.$moment(this.creationDateRange[0])
          ) &&
            this.$moment(c.createdAt).isSameOrBefore(
              this.$moment(this.creationDateRange[1])
            ))

        const matchesCameraStatus =
          !this.selectedCameraStatus || c.status === this.selectedCameraStatus

        return (
          c.cameraModel === this.selectedCameraModel &&
          matchesCameraStatus &&
          matchesFeatureFlag &&
          matchesDateRange
        )
      })
    },
  },
  watch: {
    filteredCameras: {
      immediate: true,
      handler(v) {
        this.$emit("change", v)
      },
    },
  },
  methods: {
    onGlobalSearchResultsChange(results: GlobalSearchResult[]) {
      this.isSearchActive = true
      this.globalSearchResults = results
    },
    onGLobalSearchResultsReset() {
      this.isSearchActive = false
    },
    getStatusColor(item) {
      return CAMERA_STATUSES_IDS_CLASSES[item.value]?.class || ""
    },
    onCreationDateRangeChange() {
      this.isCreationDatePickerOpened = false
      if ((this.creationDateRange || [])?.length < 2) {
        return
      }

      if (
        this.$moment(this.creationDateRange[0]).isAfter(
          this.$moment(this.creationDateRange[1])
        )
      ) {
        this.creationDateRange = [
          this.creationDateRange[1],
          this.creationDateRange[0],
        ]
      }
    },
  },
})
</script>
